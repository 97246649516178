<template>
  <div>
    <!-- mobile view -->
    <v-card
      class="
        pa-5
        d-md-none d-lg-none d-xl-none d-flex
        flex-column
        justify-center
        align-center
      "
    >
      <div class="mb-5">
        <img src="../../../assets/image/kominfo.png" alt="kominfo" />
      </div>
      <div class="text-center mb-5">
        <div
          class="font-weight-bold mb-2"
          style="color: #4f4f4f; font-size: 16px"
        >
          Dashboard
        </div>
        <div
          class="font-weight-bold mb-5"
          style="color: #4f4f4f; font-size: 16px"
        >
          Pusat Monitoring Telekomunikasi
        </div>
        <div class="font-weight-bold mb-5" style="color: #4f4f4f">
          Change Password
        </div>
        <div class="grey--text">Masukan password baru dan konfirmasi</div>
        <div class="grey--text">untuk melanjutkan</div>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="password"
          name="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          outlined
          single-line
          placeholder="Password baru"
          @click:append="show1 = !show1"
          :rules="passwordRules"
        ></v-text-field>
        <v-text-field
          v-model="new_password"
          name="confirmPassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          outlined
          single-line
          placeholder="Konfirmasi password baru"
          @click:append="show2 = !show2"
        ></v-text-field>
        <v-btn
          @click="changePassword"
          width="100%"
          elevation="0"
          color="#169CD6"
          class="white--text pa-6"
          :disabled="!valid"
        >
          Submit
        </v-btn>
        <v-btn
          @click="toHome"
          width="100%"
          elevation="0"
          color="warning"
          class="white--text pa-6 mt-3"
        >
          Back
        </v-btn>
      </v-form>
      <v-row class="mt-5" style="margin-bottom: 95px">
        <v-col xl="4" lg="4" md="4" sm="4" xs="4">
          <img
            class="float-right"
            width="90%"
            src="../../../assets/image/image7.png"
            alt="image7"
          />
        </v-col>
        <v-col class="text-center" xl="4" lg="4" md="4" sm="4" xs="4">
          <img src="../../../assets/image/moda.png" alt="moda" />
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" xs="4">
          <img
            width="95%"
            src="../../../assets/image/image9.png"
            alt="image8"
          />
        </v-col>
      </v-row>
      <v-dialog class="d-flex d-sm-none" v-model="dialogMobile" width="100%">
        <v-card
          class="
            notif
            pa-5
            d-flex
            flex-column
            text-center
            justify-center
            align-center
          "
        >
          <img
            class="logo mt-5"
            src="../../../assets/image/change-password.svg"
            alt=""
          />
          <div class="pa-5">
            <div>Your password has been successfuly changed</div>
            <div class="mt-2">Please login on your apps</div>
          </div>
          <v-btn
            @click="dialogMobile = false"
            width="50%"
            elevation="0"
            color="#169CD6"
            class="white--text pa-6 mb-5"
            >OK</v-btn
          >
        </v-card>
      </v-dialog>
    </v-card>
    <div class="pmt-login d-none d-lg-flex d-xl-flex">
      <!-- web view -->
      <v-card
        class="crd-login pa-5"
        elevation="2"
        style="border-radius: 25px !important"
      >
        <div class="kominfo-logo d-flex justify-center align-center mt-15 mb-5">
          <img src="../../../assets/image/kominfo.png" alt="kominfo" />
        </div>
        <div class="text-center">
          <div
            class="font-weight-bold mb-2"
            style="color: #4f4f4f; font-size: 20px"
          >
            Dashboard
          </div>
          <div
            class="font-weight-bold mb-5"
            style="color: #4f4f4f; font-size: 20px"
          >
            Pusat Monitoring Telekomunikasi
          </div>
          <div class="font-weight-bold mb-5" style="color: #4f4f4f">
            Change Password
          </div>
          <div class="grey--text">Masukan password baru dan konfirmasi</div>
          <div class="grey--text">untuk melanjutkan</div>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-8 pl-10 pr-10">
            <v-col xl="12" lg="12" md="12" sm="12" xs="12">
              <v-text-field
                v-model="password"
                name="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                outlined
                single-line
                placeholder="Current Password"
                @click:append="show1 = !show1"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
            <v-col xl="12" lg="12" md="12" sm="12" xs="12">
              <v-text-field
                v-model="new_password"
                name="confirmPassword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                outlined
                single-line
                placeholder="New Password"
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
            <v-col xl="12" lg="12" md="12" sm="12" xs="12">
              <v-btn
                @click="changePassword"
                width="100%"
                elevation="0"
                color="#169CD6"
                class="white--text pa-6"
                :disabled="!valid"
              >
                Submit
              </v-btn>
              <v-btn
                @click="toHome"
                width="100%"
                elevation="0"
                color="warning"
                class="white--text pa-6 mt-3"
              >
                Back
              </v-btn>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" xs="4">
              <img
                class="float-right"
                width="60%"
                src="../../../assets/image/image7.png"
                alt="image7"
              />
            </v-col>
            <v-col class="text-center" xl="4" lg="4" md="4" sm="4" xs="4">
              <img src="../../../assets/image/moda.png" alt="moda" />
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" xs="4">
              <img
                width="62%"
                src="../../../assets/image/image9.png"
                alt="image8"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-dialog v-model="dialog" width="30%">
          <v-card
            class="
              notif
              pa-5
              d-flex
              flex-column
              text-center
              justify-center
              align-center
            "
          >
            <img
              class="logo mt-5"
              src="../../../assets/image/change-password.svg"
              alt=""
            />
            <div class="pa-5">Your password has been successfuly changed</div>
            <v-btn
              @click="submitResetPassword"
              width="50%"
              elevation="0"
              color="#169CD6"
              class="white--text pa-6 mb-5"
              >OK</v-btn
            >
          </v-card>
        </v-dialog>
      </v-card>
    </div>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import JwtServices from "../../../services/jwt.services";
// import Vue from "vue";
// import { VueReCaptcha } from "vue-recaptcha-v3";

// Vue.use(VueReCaptcha, {
//   siteKey: "6LfcvUgbAAAAAPHFpbhxBctuVQ_eKWLM9cDxKoaO",
//   loaderOptions: {
//     useRecaptchaNet: true,
//   },
// });

export default {
  data() {
    return {
      valid: true,
      loading_overlay: false,
      user: null,
      username: null,
      show1: false,
      show2: false,
      password: "",
      new_password: "",
      dialog: false,
      dialogMobile: false,
      vw: 0,
      passwordRules: [(value) => !!value || "Please type password"],
      confirmPassword: [
        (value) => !!value || "Type confirm password",
        (value) =>
          value === this.password || "The password confirmation does not match",
      ],
    };
  },

  created() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    this.user = JwtServices.getUser();

    this.vw = vw;
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    console.log(this.$route.query, vw, vh);
  },

  methods: {
    toHome() {
      this.$router.push({ name: "Landing Page" });
    },

    changePassword() {
      if (!this.validate()) return;
      // const token = this.$route.query.token;
      this.loading_overlay = true;
      const data = {
        id: this.user.id,
        username: this.user.username,
        current_password: this.password,
        new_password: this.new_password,
      };

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/account/change-password`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JwtServices.getToken()}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.loading_overlay = false;
          if (result.error == true) {
            this.$swal("Opps", result.message, "error");
          } else {
            this.$swal({
              title: "Success",
              text: result.message,
              timer: 1000,
              showConfirmButton: false,
              icon: "success",
            });
            this.toHome();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submitResetPassword() {
      this.dialog = false;
      this.$router.push({ name: "Login" });
    },

    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.pmt-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 70px;
}
</style>
